import axios from 'axios'

export default {
  createGroup (data) {
    return axios.post(process.env.VUE_APP_GROUP, data)
  },

  updateGroup (id, data) {
    return axios.put(process.env.VUE_APP_GROUP + '/' + id, data)
  },

  getGroupDetails (id) {
    return axios.get(process.env.VUE_APP_GROUP + '/' + id)
  },

  getGroup (clientId) {
    return axios.get(process.env.VUE_APP_GROUP + '/by_client/' + clientId)
  },

  deleteGroup (id) {
    return axios.delete(process.env.VUE_APP_GROUP + '/' + id)
  },

  getUnGroupedCourses (groupId, clientId) {
    return axios.get(process.env.VUE_APP_GROUP + '/available_course/' + groupId + '/' + clientId)
  },

  getGroupedCourses (groupId) {
    return axios.get(process.env.VUE_APP_GROUP + '/group_course/' + groupId)
  },

  groupMultipleCourse (data) {
    return axios.post(process.env.VUE_APP_GROUP_COURSE + '/multiple', data)
  },

  deleteGroupCourse (id) {
    return axios.delete(process.env.VUE_APP_GROUP_COURSE + '/' + id)
  }
}
