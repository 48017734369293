
import { defineComponent, createVNode, ref, reactive, watch, toRefs, onMounted } from 'vue'
import { HomeOutlined, UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import groupService from '../../../services/group'
import commonServices from '../../../services/common'

import ICourse from '../../../interface/course'
interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    UserAddOutlined
  },
  setup () {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const route = useRoute()
    const groupId = ref<string>(route.params.id as string)
    const group = ref<Group>()
    const unassignedCourses = ref<Array<ICourse>>([])
    const assignedCourses = ref<Array<any>>([])
    const availableCourse = ref([])
    const loading = ref<boolean>(false)
    const courseColumns = [
      {
        title: 'Course Name',
        dataIndex: 'course.title',
        onFilter: (value, record) => record.course.title.indexOf(value) === 0,
        sorter: (a, b) => a.course.title.length - b.coursename.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Type',
        dataIndex: 'course.type'
      },
      {
        title: 'Price',
        dataIndex: 'course.price',
        onFilter: (value, record) => record.course.price.indexOf(value) === 0,
        sorter: (a, b) => a.course.price.length - b.course.price.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'price' }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroupDetails = async () => {
      try {
        const responce = await groupService.getGroupDetails(groupId.value)
        group.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const removeCourse = async (id, index) => {
      try {
        await groupService.deleteGroupCourse(id)
        assignedCourses.value.splice(index, 1)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCourse = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await removeCourse(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const visible = ref<boolean>(false)
    const courseDrawer = () => {
      visible.value = true
    }
    const state = reactive({
      indeterminate: false,
      selectAllCourse: false,
      selectedCourse: []
    })
    const closeCourseListModal = () => {
      visible.value = false
    }
    const getUnGroupedCourses = async () => {
      try {
        availableCourse.value = []
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await groupService.getUnGroupedCourses(groupId.value, clientId)
        unassignedCourses.value = responce.data
        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getGroupedCourses = async () => {
      try {
        availableCourse.value = []
        const responce = await groupService.getGroupedCourses(groupId.value)
        assignedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const assignCourse = async () => {
      try {
        const enroll = []
        loading.value = true
        for (const courseId of state.selectedCourse) {
          enroll.push({ group: groupId.value, course: courseId })
        }
        await groupService.groupMultipleCourse(enroll)
        loading.value = false
        closeCourseListModal()
        getGroupedCourses()
        notify('Success', 'Course Assigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    watch(() => state.selectedCourse, (val) => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.selectAllCourse = val.length === availableCourse.value.length
    })
    onMounted(() => {
      getGroupDetails()
      getUnGroupedCourses()
      getGroupedCourses()
    })
    return {
      courseColumns,
      confirmDeleteCourse,
      visible,
      notify,
      courseDrawer,
      onCheckAllChange,
      ...toRefs(state),
      closeCourseListModal,
      group,
      assignCourse,
      availableCourse,
      loading,
      assignedCourses,
      loginAs
    }
  }
})
